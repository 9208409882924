import React from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Button,
  Upload,
  Icon,
  message,
  Row,
  Col,
} from "antd";
import moment from "moment";
import { withApollo } from "react-apollo";
import { compose } from "recompose";
import styled from "styled-components";
import { withGlobal } from "../../../component-global";
import { withFirebase } from "../../../firebase";

const UploadComponent = styled(Upload)`
  .ant-upload.ant-upload-select {
    width: 100%;
  }
`;

const { Option } = Select;

class CustomInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validFormats: [
        "",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
    };
  }

  fileNameStamp = name => {
    const getDate = new Date();
    getDate.setHours(getDate.getHours() - 5);
    let timestamp = getDate.toISOString();
    timestamp = timestamp.replace(/:/g, "-");
    timestamp = timestamp.replace(/\./g, "-");
    return timestamp + name.replace(/ /g, "_");
  };

  beforeUpload = file => {
    if (this.state.validFormats) {
      if (this.state.validFormats.indexOf(file.type) >= 0) {
        return true;
      }
      message.error("Suba archivos válidos (.png, .jpg, .jpeg .pdf, .docx)");
      return false;
    }
    return true;
  };

  handleDelete = async () => {
    await this.props.onDeleteImgProfile();
  };

  customPost = ({ file, onError, onProgress, onSuccess }) => {
    const { firebase } = this.props;

    const fileStamp = this.fileNameStamp(file.name);
    const key = `gameboy_files/${this.props.decorator}/${fileStamp}`;

    const task = firebase.refStorage.child(key).put(file);

    task.on(
      "state_changed",
      function progress(snapshot) {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress({ percent: percentage });
      },
      err => {
        message.error("Error al subir archivo");
        return onError(err);
      },
      () => {
        task.snapshot.ref.getDownloadURL().then(downloadURL => {
          const { name, uid, url = downloadURL, status = "done" } = file;
          onSuccess({ name, uid, url, status, file: downloadURL });
          message.success(`El archivo se ha subido con éxito.`);
        });
      }
    );
  };

  handleChange = info => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      if (file.response) {
        // eslint-disable-next-line no-param-reassign
        file.url = file.response.url;
      }
      return file;
    });
    this.setState({ fileList });
  };

  displayInputType = (
    type,
    placeholder,
    disabled,
    options,
    value,
    getFieldDecorator,
    decorator,
    required
  ) => {
    let response = null;
    switch (type) {
      case "text":
        response = (
          <Form.Item>
            {getFieldDecorator(decorator, {
              initialValue: value || undefined,
              rules: [
                {
                  required,
                  message: "Este campo es obligatorio",
                },
              ],
            })(
              <Input
                size="large"
                placeholder={placeholder}
                disabled={disabled}
              />
            )}
          </Form.Item>
        );
        break;
      case "select":
        response = (
          <Form.Item>
            {getFieldDecorator(decorator, {
              initialValue: (value && parseInt(value, 10)) || undefined,
              rules: [
                {
                  required,
                  message: "No se seleccionó ninguna opción",
                },
              ],
            })(
              <Select
                style={{ width: "100%" }}
                size="large"
                disabled={disabled}
                placeholder={placeholder}
                onChange={this.props.onSelectChanse}
              >
                {(options || []).map(obj => {
                  return (
                    <Option key={obj.subtype} value={obj.subtype}>
                      {obj.type === 23
                        ? `${obj.subclass} - ${
                            obj.extra.documentName ? obj.extra.documentName : ""
                          }`
                        : obj.subclass}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>
        );
        break;
      case "calendar":
        response = (
          <Form.Item>
            {getFieldDecorator(decorator, {
              initialValue: (value && moment(value)) || undefined,
              rules: [
                {
                  required,
                  message: "Este campo es obligatorio",
                },
              ],
            })(
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                onChange={(e) => console.log("Value:", e)}
                placeholder={placeholder}
                disabled={disabled}
              />
            )}
          </Form.Item>
        );
        break;
      case "upload":
        response = (
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item getValueFromEvent={this.normFile}>
                {getFieldDecorator(decorator, {
                  initialValue: value || undefined,
                  rules: [
                    {
                      required,
                      message: this.props.message,
                      validator: async () => {
                        if (
                          value ||
                          (this.state.fileList && this.state.fileList.length)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("No se subio el archivo")
                        );
                      },
                    },
                  ],
                })(
                  <UploadComponent
                    multiple={false}
                    beforeUpload={this.beforeUpload}
                    customRequest={this.customPost}
                    headers={{
                      "X-Requested-With": null,
                    }}
                    listType="list"
                    fileList={
                      this.state.fileList ||
                      (value && [
                        {
                          url: value,
                          uid: "1",
                          name: "Descargar archivo",
                          status: "done",
                        },
                      ])
                    }
                    onChange={this.handleChange}
                  >
                    <Button
                      size="large"
                      style={{
                        background: "white",
                        color: "#307FE2",
                        width: "100%",
                      }}
                      disabled={disabled}
                    >
                      {placeholder}
                      <Icon type="upload" />
                    </Button>
                  </UploadComponent>
                )}
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      case "check":
        response = (
          <Form.Item>
            {getFieldDecorator(decorator, {
              valuePropName: "checked",
              initialValue: value === "false",
              rules: [
                {
                  required,
                  message: this.props.message,
                  validator: async (_, values) => {
                    if (!required || values) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Este campo es obligatorio")
                    );
                  },
                },
              ],
            })(<Checkbox>{placeholder}</Checkbox>)}
          </Form.Item>
        );
        break;
      default:
        response = (
          <Form.Item>
            {getFieldDecorator(decorator, {
              initialValue: value || undefined,
              rules: [
                {
                  required,
                  message: "Este campo es obligatorio",
                },
              ],
            })(
              <Input
                size="large"
                placeholder={placeholder}
                disabled={disabled}
              />
            )}
          </Form.Item>
        );
        break;
    }
    return response;
  };

  render() {
    const {
      placeholder,
      getFieldDecorator,
      decorator,
      required,
      value,
      disabled,
      type,
      options,
    } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: 60,
        }}
      >
        {this.displayInputType(
          type,
          placeholder,
          disabled,
          options,
          value,
          getFieldDecorator,
          decorator,
          required
        )}
      </div>
    );
  }
}
const UploadCustomInput = withFirebase(CustomInput);

const WraperCustomInput = withGlobal(UploadCustomInput);
const WraperCustomInputForm = Form.create()(WraperCustomInput);

export default compose(withApollo)(WraperCustomInputForm);
